/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

//import axios from 'axios';
//const axios = require("axios");

(function ($) {
	
    var Sage = {
		
        // All pages
        'common': {
			
            init: function () {
                //var statelocal = randomString(16);

                //document.cookie = "auth0_state =" + statelocal ;

                // JavaScript to be fired on all pages
				
                //Auth0.js
                window.addEventListener('load', function () {

                    // var webAuth = new auth0.WebAuth({
                    // domain:       'amdese.auth0.com',
                    // clientID:     'clientid',
                    // //redirectUri:  'https://devhub.amd.com/index.php?auth0=1',

                    
                    // scope:        'openid profile email',

                    // //state: statelocal,
                    // responseType: 'code'
                    // responseMode: 'form_post'
                    // });
                    // var GuestloginBtn = document.getElementById('Customer');
                    // if (GuestloginBtn) {
                        // GuestloginBtn.addEventListener('click', function (e) {
                            // e.preventDefault();
                            // //webAuth.login({});
                             // webAuth.authorize({
                             // connection:   'ExtAMDFed'
                             // }, function (err) {
                            // // // handle error calling Auth0
                             // });
                        // });
                    // }

                    // var EmployeeloginBtn = document.getElementById('Employee');
                    // //var EmployeeloginBtn = document.getElementById('emp');
                    // if (EmployeeloginBtn) {
                        // EmployeeloginBtn.addEventListener('click', function (e) {
                            // e.preventDefault();
                            // //webAuth.login({});
                            // //console.log("This is a test message.");
                            // webAuth.authorize({
                            // connection:   'AMDFedProd'
                            // }, function (err) {
                            // // handle error calling Auth0
                            // });

                        // });
                    // }

                });
                //Play video player when modal closes for video js
                //$('.vid-modal').on('shown.bs.modal', '.modal', function () {
                // $('video').trigger('play');
                //});

                //Pause video player when modal closes for video js
                $('body').on('hidden.bs.modal', '.modal', function () {
                    $('video').trigger('pause');
                });

                //Pause video player when modal closes for embeded youtube videos
                $(".vid-modal").on('hidden.bs.modal', function (e) {
                    $(".vid-modal iframe").attr("src", $(".vid-modal iframe").attr("src"));
                });

                /* main navigation - mmenu init & config */

                var mainNav = $('#mainNav');
                $(mainNav).mmenu({
                    // options
                    offCanvas: false,

                    navbar: {
                        "title": "Main Menu"
                        //add: false
                    },
                    /*
                    "navbars": [
                      {
                        "position": "top",
                        "type": "tabs",
                        "content": [
                          "<a href='#menu-product-family'>Product Family</a>",
                          "<a href='#panel-solutions'>Solutions</a>"
                        ]
                      }
                    ],
                    */
                    "setSelected": {
                        "hover": true,
                        "parent": true
                    },
                    "counters": true,
                    "iconPanels": true,
                    wrappers: ["wordpress"]
                    /*},{
                     // configuration
                     classNames: {
                      selected: "active"
                     }
                     */
                });

                /* solutions navigation - mmenu init & config */
                /*
                var solNav = $('#solutionsNav');
                $(solNav).mmenu({
                   "counters": true,
                   "navbar": {
                      "title": "Solutions"
                   }
                });
        
                /* main navigation - navigate to active menu level on page load
                if( $('#mainNav .menu-item.active:last').length ) {
                  var api = $(mainNav).data('mmenu');
                  var current = $('.menu-item.active:last');
                  api.setSelected(current);
                  api.openPanel(current.closest(".mm-panel"));
                }
                */

                /* main navigation - add class to parent menu items to make the whole link area clickable */
                /*** this removes the divider and makes only one clickable area instead of two ***/
                /*$("#mainNav .menu-item-has-children a").each(function() {
                  $(this).addClass('mm-fullsubopen');
                });*/

                /* main navigation add height to panel
                var navTopHeight = $('#navTop').outerHeight();
                var navHeaderHeight = $('#navHeader').outerHeight();
                var menuHeight = navTopHeight - navHeaderHeight;
                $('#mainNav').css('height', menuHeight);
        
        
                /* Toggle Navigation */
                $('#navToggle').click(function () {
                    $('#outerWrapper').addClass('nav-open');
                });
                $('#navClose, #wrapOverlay').click(function () {
                    $('#outerWrapper').removeClass('nav-open');
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                /*
                ** Wait until the page loads, then hide the loading screen
                */
                $(window).bind("load", function () {
                    setTimeout(function () {
                        $('#loading').fadeOut('fast', function () {
                            $(this).css('display', 'none');
                        });
                    }, 500);
                });

                /* DataTables */
                $('.tabs-table').DataTable({
                    responsive: true,
                    "paging": false,
                    "filter": false,
                    "info": false
                });

                /* DataTables */
                $('#ResourceTable').DataTable({
                    "order": [[3, "desc"]],
                    responsive: true,
                    dom: 'f<"top"lB>rt<"bottom"ip><"clear">',
                    buttons: [
                        'excel'
                    ],
                    language: {
                        search: "_INPUT_",
                        searchPlaceholder: "Search"
                    }
                });
                /* Move DataTables search to the header on Recent Updates page only */
                $("#FilterTableSeach").html($(".dataTables_filter"));

                /*
                * SLA Modals
                */
                var button;
                var modal;
                var bodyContent;
                var modalBody;

                $("#slaModal").on({
                    //When the bootstrap modal is shown
                    'shown.bs.modal': function (event) { // Load modal data

                        //vars
                        modal = $(this); // Modal we're on
                        button = $(event.relatedTarget); // Button that triggered the modal
                        modalBody = modal.find('.modal-body'); // Get the modal-body element

                        var title = button.data('title'); // Get the title from data- attributes

                        modal.find('.modal-title').text(title); // Add the title to the modal title

                        bodyContent = button.find('.form-body'); // Get the form html/code from the storage area
                        $(bodyContent).appendTo(modalBody); // Move the form code into the modal-body

                        // Add the decline button
                        var declineButton = '<button type="button" class="btn btn-lg btn-default" data-dismiss="modal" aria-label="Decline">Decline</button>',
                            formFooter = modal.find('.gform_footer');
                        if ((modal.find('.gform_footer .btn-default')).length === 0) {
                            $(declineButton).prependTo(formFooter);
                        }

                        // unfocus the button
                        $(button).one('focus', function (e) { $(this).blur(); });

                    },
                    // When the bootstrap modal is hidden
                    'hidden.bs.modal': function () { // When the modal closes...

                        // Move the form code back into the storage area
                        bodyContent = modal.find('.form-body');
                        $(bodyContent).appendTo(button);

                        // Clear the modal content
                        modal.find('.modal-body, .modal-title').html('');

                    }
                });

                // Attach events to the confirmation loaded page
                $(document).bind('gform_confirmation_loaded', function () {

                    // Show the download link just in case
                    $('#slaModal .confirmation-link').css('display', 'block');

                    // Open the download
                    var url = button.data('link');
                    var newWnd = window.open(url, '_blank');
                    newWnd.opener = null;

                    // Hide the modal after a few seconds
                    setTimeout(function () {
                        $('#slaModal').modal('hide');
                    }, 10000);
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                $(".video-holder").fitVids();

                /* Append twitter widget */
                var twitterNode = '<a class="twitter-timeline" data-chrome="noheader nofooter noborders transparent" data-link-color="#00aab5" href="https://twitter.com/AMDembedded" data-tweet-limit="5">Tweets by AMDembedded</a>';
                $("#twitterFrame").html(twitterNode);

            }
        },
        // Recent Updates page
        'recent-updates': {
            init: function () {
                // JavaScript to be fired on the Recent Updates page

            }
        },
        // Single Product Page
        'single': {
            init: function () {
                // JavaScript to be fired on single post page

                // Do this when the window loads
                $(window).bind("load", function () {



                    /*
                    ** Show More on Overview Panel
                    */
                    var overview = $('#overviewPanel .panel-body')[0];
                    // If there's more text than the panel shows, remove the scroll bar and add the "see more" button
                    if (overview.offsetHeight < overview.scrollHeight) {
                        $('#overviewPanel .panel-body').css('overflow-y', 'hidden');
                        $('#showMore').css('display', 'block');
                        $('#overviewPanel').addClass('panel-collapsed');
                        // When the Show More button is clicked, hide the show more div and add the scroll bar back
                        $("#showMore button").click(function () {
                            $('#overviewPanel .panel-body').css('overflow-y', 'scroll');
                            $('#overviewPanel').removeClass('panel-collapsed');
                            $('#showMore').fadeOut('fast', function () {
                                $(this).css('display', 'none');
                            });
                            // Scroll to the top of Key Features
                            $('#overviewPanel .panel-body').animate({
                                scrollTop: $("#keyFeatures").offset().top
                            }, 500);
                        });
                    }


                }); //end $(window).bind("load", function()

                // Add spans inside <th> elements
                $(".table-header-rotated th").wrapInner("<div></div>");

            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
